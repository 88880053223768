import { PERMISSION_TYPES } from "./enums";

const LINKS = Object.freeze({
  AUTH: {
    TITLE: "Auth",
    HREF: "/auth",
  },
  SIGN_IN: {
    TITLE: "Sign In",
    HREF: "/auth/sign-in",
  },
  RESET_PASSWORD: {
    TITLE: "Reset Password",
    HREF: "/auth/reset-password/:passwordResetToken",
  },
  FORGOT_PASSWORD: {
    TITLE: "Forgot Password",
    HREF: "/auth/forgot-password",
  },
  NOT_FOUND: {
    TITLE: "404 Page",
    HREF: "/auth/404",
  },
  ERROR_PAGE: {
    TITLE: "500 Page",
    HREF: "/auth/500",
  },
  ACCESS_DENIED: {
    TITLE: "Access Denied",
    HREF: "/auth/access-denied",
  },
  OVERVIEW: {
    TITLE: "Overview",
    HREF: "/overview",
  },
  EDIT_PROFILE: {
    TITLE: "Profile",
    HREF: "/profile/edit",
  },
  PROJECT_MANAGEMENT: {
    TITLE: "Project Management",
    HREF: "/project-management",
    ROLES: [
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  DASHBOARD: {
    TITLE: "Dashboard",
    HREF: "/project-management/dashboard",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  DASHBOARD_DETAIL: {
    TITLE: "Dashboard Detail",
    HREF: "/project-management/dashboard/:id",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  PROJECTS: {
    TITLE: "Projects",
    HREF: "/project-management/projects",
  },
  ADD_PROJECT_DATA: {
    TITLE: "Add Project Data",
    HREF: "/project-control-management/project-data/add",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  PROJECT_DATA: {
    TITLE: "Project Data",
    HREF: "/project-control-management/project-data",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  EDIT_PROJECT_DATA: {
    TITLE: "Edit Project Control",
    HREF: "/project-control-management/project-data/edit/:id/",
    ROLES: [PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin],
  },
  CLIENTS_ORGANIZATION: {
    TITLE: "Clients",
    HREF: "/project-control-management/clients",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  // EDIT_ORGANIZATION_CLIENTS: {
  //   TITLE: "Client Configuration",
  //   HREF: "/project-control-management/organization/:id",
  //   ROLES: [PERMISSION_TYPES.superAdmin],
  // },
  BUDGET_TRACKING: {
    TITLE: "Budget Tracker",
    HREF: "/project-control-management/budget-tracking",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  BUDGET_ALERTS: {
    TITLE: "Budget Alerts",
    HREF: "/project-control-management/budget-alerts",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  PURCHASE_ORDERS: {
    TITLE: "Purchase Orders",
    HREF: "/project-control-management/purchase-orders",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  PROPOSALS: {
    TITLE: "Proposals",
    HREF: "/project-control-management/proposals",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  TIME_ENTRY_REPORTS: {
    TITLE: "Time Entry Reports",
    HREF: "/project-control-management/timeentry-reports",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  EXPENSES_PROJECT_CONTROL: {
    TITLE: "Expenses Approval",
    HREF: "/project-control-management/expenses",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  EXPENSES: {
    TITLE: "Expenses",
    HREF: "/time-management/expenses",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  ADD_EXPENSE_PROJECT_CONTROL: {
    TITLE: "Add Expense",
    HREF: "/project-control-management/expenses/add",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  ADD_EXPENSE: {
    TITLE: "Add Expense",
    HREF: "/time-management/expenses/add",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  EDIT_EXPENSE_PROJECT_CONTROL: {
    TITLE: "Edit Expense",
    HREF: "/project-control-management/expenses/edit/:id",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  EDIT_EXPENSE: {
    TITLE: "Edit Expense",
    HREF: "/time-management/expenses/edit/:id",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  EXPENSE_REPORT: {
    TITLE: "Expense Report",
    HREF: "/project-control-management/expense-report",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  ADMINISTRATION_SETTING: {
    TITLE: "Settings",
    HREF: "/project-control-management/settings",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  INVOICE_TIME_ENTRY: {
    TITLE: "Invoice Time Entry",
    HREF: "/project-control-management/invoice-time-entry",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  INVOICE_TIMESHEET: {
    TITLE: "Invoice Timesheet",
    HREF: "/project-control-management/invoice-time-entry/timesheet/:userId",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  INVOICES: {
    TITLE: "Invoices",
    HREF: "/project-control-management/invoices",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  INVOICES_REPORTS: {
    TITLE: "Invoice Reports",
    HREF: "/project-control-management/invoice-reports",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  INVOICES_APPROVALS: {
    TITLE: "Invoice Approvals",
    HREF: "/project-control-management/invoice-approvals",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  ADD_INVOICE: {
    TITLE: "Add Invoice",
    HREF: "/project-control-management/invoice/add",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  EDIT_INVOICE: {
    TITLE: "Edit Invoice",
    HREF: "/project-control-management/invoice/edit/:id",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  RISK_ASSESSMENT: {
    TITLE: "Risk Assessment",
    HREF: "/risk-assessment-management/risk-assessment/",
    ROLES: [PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin],
  },
  ADD_RISK_ASSESSMENT: {
    TITLE: "Add Risk Assessment",
    HREF: "/risk-assessment-management/risk-assessment/add",
    ROLES: [PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin],
  },
  EDIT_RISK_ASSESSMENT: {
    TITLE: "Edit Risk Assessment",
    HREF: "/risk-assessment-management/risk-assessment/edit/:id",
    ROLES: [PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin],
  },
  RISK_ASSESSMENT_REPORT: {
    TITLE: "Report",
    HREF: "/risk-assessment-management/report",
    ROLES: [PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin],
  },
  DELIVERY: {
    TITLE: "Projects",
    HREF: "/project-management/projects",
  },
  QMS: {
    TITLE: "QMS",
    HREF: "/project-management/quality-management-systems",
  },
  ADD_PROJECT: {
    TITLE: "Add Project",
    HREF: "/project-management/projects/add",
  },
  EDIT_PROJECT: {
    TITLE: "Edit Project",
    HREF: "/project-management/projects/edit/:id",
  },
  CELL_GLASS_SETTINGS: {
    TITLE: "Cell Glass Settings",
    HREF: "/project-management/projects/edit/:projectId/patient-cell-glass/settings",
  },
  PROJECT_MODULE_PREFERENCE: {
    TITLE: "Module Preference",
    HREF: "/project-management/projects/edit/:projectId/module-preferences",
  },
  SYSTEM_TREND_CHART: {
    TITLE: "System Trend Chart",
    HREF: "/project-management/projects/edit/:projectId/system-trend-chart",
  },
  PROJECT_HISTORY: {
    TITLE: "Project History",
    HREF: "/project-management/projects/edit/:projectId/history",
  },
  PROJECT_INSIGHT: {
    TITLE: "Insights",
    HREF: "/project-management/projects/edit/:projectId/project-insights",
  },
  DELIVERABLE_SNAPSHOT: {
    TITLE: "Deliverable Snapshot",
    HREF: "/project-management/projects/edit/:projectId/snapshot",
  },
  DOCUMENT_SNAPSHOT: {
    TITLE: "Document Snapshot",
    HREF: "/project-management/projects/edit/:projectId/document-snapshot",
  },
  DELIVERABLE_MATRIX: {
    TITLE: "Deliverable Matrix",
    HREF: "/project-management/projects/edit/:projectId/matrix",
  },
  PHASE_SNAPSHOT: {
    TITLE: "Phase Snapshot",
    HREF: "/project-management/projects/edit/:projectId/phase-snapshot",
  },
  SYSTEM_SNAPSHOT: {
    TITLE: "System Snapshot",
    HREF: "/project-management/projects/edit/:projectId/system-snapshot",
  },
  PROJECT_SCHEDULER: {
    TITLE: "Scheduler",
    HREF: "/project-management/projects/edit/:projectId/scheduler",
  },
  PROJECT_FINANCE: {
    TITLE: "Finance",
    HREF: "/project-management/projects/edit/:projectId/finance",
  },
  PROJECT_SYSTEMS: {
    TITLE: "Systems",
    HREF: "/project-management/projects/edit/:projectId/project-systems",
  },
  PROJECT_PHASES: {
    TITLE: "Project Phases",
    HREF: "/project-management/projects/edit/:projectId/phases",
  },
  PROJECT_CALENDAR: {
    TITLE: "Project Calendar",
    HREF: "/project-management/projects/edit/:projectId/calendar",
  },
  PROJECT_DOCUMENTS: {
    TITLE: "Documents",
    HREF: "/project-management/projects/edit/:projectId/documents",
  },
  DOCUMENTS_CHANGE_REQUEST: {
    TITLE: "Documents Change Request",
    HREF: "/project-management/projects/edit/:projectId/documents/dcr",
  },
  ADD_DOCUMENT: {
    TITLE: "Add Document",
    HREF: "/project-management/projects/edit/:projectId/documents/add",
  },
  EDIT_DOCUMENTS: {
    TITLE: "Edit Document",
    HREF: "/project-management/projects/edit/:projectId/documents/edit/:id",
  },
  DOCUMENT_SETTINGS: {
    TITLE: "Document Settings",
    HREF: "/project-management/projects/edit/:projectId/documents/settings",
  },
  VIEW_DOCUMENTS: {
    TITLE: "View Document",
    HREF: "/project-management/projects/edit/:projectId/documents/edit/:id/view/:documentId",
  },
  ADOBE_VIEW: {
    TITLE: "View Adobe",
    HREF: "/project-management/projects/edit/:projectId/documents/edit/:id/view/:documentId/adobe",
  },
  PROJECT_GANTT: {
    TITLE: "Project Gantt Sample",
    HREF: "/project-management/projects/gantt-sample",
  },
  SYSTEMS: {
    TITLE: "Systems",
    HREF: "/project-management/systems",
  },
  ADD_SYSTEM: {
    TITLE: "Add System",
    HREF: "/project-management/system/add",
  },
  EDIT_SYSTEM: {
    TITLE: "Edit System",
    HREF: "/project-management/system/edit/:id",
  },
  SYSTEM_HISTORY: {
    TITLE: "System History",
    HREF: "/project-management/systems/edit/:id/history",
  },
  ADD_META_SYSTEM: {
    TITLE: "Add System",
    HREF: "/project-management/projects/edit/:projectId/meta-system/add",
  },
  EDIT_META_SYSTEM: {
    TITLE: "Edit System",
    HREF: "/project-management/projects/edit/:projectId/meta-system/edit/:systemId/:mainSystemId",
  },
  EDIT_META_SYSTEM_INCOMPLETE: {
    TITLE: "Edit System",
    HREF: "/project-management/projects/edit/:projectId/meta-system/edit/:systemId/:mainSystemId/incomplete",
  },
  EDIT_DELIVERABLE: {
    TITLE: "Edit Deliverable",
    HREF: "/project-management/projects/edit/:projectId/meta-system/edit/:systemId/:mainSystemId/deliverable/:deliverableId",
  },
  EDIT_TASK: {
    TITLE: "Edit Task",
    HREF: "/project-management/projects/edit/:projectId/meta-system/edit/:systemId/:mainSystemId/task/edit/:taskId",
  },
  ADD_TASK: {
    TITLE: "Add Task",
    HREF: "/project-management/projects/edit/:projectId/meta-system/edit/:systemId/:mainSystemId/task/add",
  },
  EDIT_ESCALATION: {
    TITLE: "Edit Escalation",
    HREF: "/project-management/projects/edit/:projectId/meta-system/edit/:systemId/:mainSystemId/escalation/edit/:escalationId",
  },
  ADD_ESCALATION: {
    TITLE: "Add Escalation",
    HREF: "/project-management/projects/edit/:projectId/meta-system/edit/:systemId/:mainSystemId/escalation/add",
  },
  DELIVERABLE_TREND_CHART: {
    TITLE: "Deliverable Trend Chart",
    HREF: "/project-management/projects/edit/:projectId/systems/edit/:systemId/deliverable-trend-chart",
  },
  NOTIFICATION_TEMPLATE: {
    TITLE: "Custom Notification",
    HREF: "/project-management/projects/edit/:projectId/custom-notification",
  },
  EDIT_NOTIFICATION_TEMPLATE: {
    TITLE: "Custom Notification",
    HREF: "/project-management/projects/edit/:projectId/custom-notification/edit/:id",
  },
  ADD_NOTIFICATION_TEMPLATE: {
    TITLE: "Custom Notification",
    HREF: "/project-management/projects/edit/:projectId/custom-notification/add",
  },
  REPORTS: {
    TITLE: "Reports",
    HREF: "/project-management/reports",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  SOWS: {
    TITLE: "SOW Tracker",
    HREF: "/sows",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  ADD_SOW: {
    TITLE: "Add New SOW",
    HREF: "/sows/add",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  EDIT_SOW: {
    TITLE: "Edit SOW",
    HREF: "/sows/edit/:id",
    ROLES: [PERMISSION_TYPES.superAdmin],
  },
  WORKFLOW_TEMPLATES: {
    TITLE: "Workflow Templates",
    HREF: "/workflow-templates",
    ROLES: [
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.projectManager,
    ],
  },
  ADD_WORKFLOW_TEMPLATE: {
    TITLE: "Add Workflow Template",
    HREF: "/workflow-template/add",
    ROLES: [
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.superAdmin,
    ],
  },
  EDIT_WORKFLOW_TEMPLATE: {
    TITLE: "Edit Workflow Template",
    HREF: "/workflow-template/edit/:id",
  },
  WORKFLOW_TEMPLATE_HISTORY: {
    TITLE: "Workflow Template History",
    HREF: "/workflow-template/edit/:id/history",
  },
  WORKFLOW_TEMPLATE_CHART: {
    TITLE: "Workflow Template Chart",
    HREF: "/workflow-template/edit/:id/chart",
  },
  USER_MANAGEMENT: {
    TITLE: "User Management",
    HREF: "/user-management",
    ROLES: [PERMISSION_TYPES.admin, PERMISSION_TYPES.superAdmin],
  },
  ADMINISTRATION: {
    TITLE: "Project Control Management",
    HREF: "/project-control-management",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  RISK_ASSESSMENT_MANAGEMENT: {
    TITLE: "Risk Assessment Management",
    HREF: "/risk-assessment-management",
    ROLES: [PERMISSION_TYPES.admin, PERMISSION_TYPES.superAdmin],
  },
  USERS: {
    TITLE: "Users",
    HREF: "/user-management/users",
    ROLES: [PERMISSION_TYPES.admin, PERMISSION_TYPES.superAdmin],
  },
  ADD_USER: {
    TITLE: "New User",
    HREF: "/user-management/users/add",
    ROLES: [PERMISSION_TYPES.admin, PERMISSION_TYPES.superAdmin],
  },
  EDIT_USER: {
    TITLE: "Edit User",
    HREF: "/user-management/users/edit/:id",
  },
  TIME_MANAGEMENT: {
    TITLE: "Time Management",
    HREF: "/time-management",
    ROLES: [
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.supervisor,
    ],
  },
  TIMESHEET: {
    TITLE: "Timesheet",
    HREF: "/time-management/timesheet",
    ROLES: [
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.supervisor,
    ],
  },
  ADD_TIMESHEET_ENTRY: {
    TITLE: "Time Entry",
    HREF: "/time-management/timesheet/timeentry/:userId",
    // ROLES: [PERMISSION_TYPES.admin, PERMISSION_TYPES.superAdmin],
  },
  TIMESHEET_PROJECT_CONTROL: {
    TITLE: "Timesheet Approval",
    HREF: "/project-control-management/timesheet-approval",
    ROLES: [
      PERMISSION_TYPES.superAdmin,
      PERMISSION_TYPES.admin,
      PERMISSION_TYPES.supervisor,
      PERMISSION_TYPES.projectManager,
      PERMISSION_TYPES.user,
      PERMISSION_TYPES.viewer,
    ],
  },
  ADD_TIMESHEET_ENTRY_PROJECT_CONTROL: {
    TITLE: "Time Entry",
    HREF: "/project-control-management/timesheet-approval/timeentry/:userId",
    // ROLES: [PERMISSION_TYPES.admin, PERMISSION_TYPES.superAdmin],
  },
  TIMESHEET_SETTING: {
    TITLE: "Setting",
    HREF: "/timesheet/setting",
    // ROLES: [PERMISSION_TYPES.admin, PERMISSION_TYPES.superAdmin],
  },
  USER_MODULE_PREFERENCES: {
    TITLE: "Module Preference",
    HREF: "/user-management/users/edit/:id/module-preferences",
  },
  USER_HISTORY: {
    TITLE: "User History",
    HREF: "/user-management/users/:id/history",
  },
  ORGANIZATIONS: {
    TITLE: "Organizations",
    HREF: "/user-management/organizations",
  },
  ADD_ORGANIZATION: {
    TITLE: "Add Organization",
    HREF: "/user-management/organizations/add",
  },
  EDIT_ORGANIZATION: {
    TITLE: "Edit Organization",
    HREF: "/user-management/organizations/edit/:id",
  },
  ORGANIZATION_MODULE_PREFERENCES: {
    TITLE: "Module Preferences",
    HREF: "/user-management/organizations/edit/:id/modulePreferences",
  },
  ORGANIZATION_HISTORY: {
    TITLE: "Organization History",
    HREF: "/user-management/organizations/:id/history",
  },
  AUDIT_TRAIL_LOGS: {
    TITLE: "Audit Trail Logs",
    HREF: "/user-management/audit-trail-logs",
    ROLES: [PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin],
  },
  AUDIT_TRAIL_LOG_DETAIL: {
    TITLE: "View Audit Trail Log",
    HREF: "/user-management/audit-trail-logs/detail/:id",
  },
  AUDIT_TRAIL_LOG_HISTORY: {
    TITLE: "Audit Trail Log History",
    HREF: "/user-management/audit-trail-logs/:id/history",
  },
  WORKLOAD_REPORT: {
    TITLE: "Workload Report",
    HREF: "/user-management/workload-report",
    ROLES: [PERMISSION_TYPES.superAdmin, PERMISSION_TYPES.admin],
  },
  RECENT_ACTIONS: {
    TITLE: "Recent Actions",
    HREF: "/recent-actions",
  },
  NOTIFICATIONS: {
    TITLE: "Notifications",
    HREF: "/notifications",
  },

  /** Deviation Links */
  PROJECT_DEVIATIONS: {
    TITLE: "Deviations",
    HREF: "/project-management/projects/edit/:projectId/deviations",
  },
  ADD_DEVIATION: {
    TITLE: "Add Deviation",
    HREF: "/project-management/projects/edit/:projectId/deviation/add",
  },
  EDIT_DEVIATIONS: {
    TITLE: "Edit Deviation",
    HREF: "/project-management/projects/edit/:projectId/deviation/edit/:id",
  },
  DEVIATION_SETTINGS: {
    TITLE: "Deviation Settings",
    HREF: "/project-management/projects/edit/:projectId/deviation/settings",
  },
  PREVIEW_DEVIATION_ATTACHMENT: {
    TITLE: "Preview Attachment",
    HREF: "/project-management/projects/edit/:projectId/deviation/edit/:id/view/:fileId",
  },

  /** DMS Template Links */
  ADD_DMS_TEMPLATE: {
    TITLE: "Add Document Template",
    HREF: "/project-management/projects/edit/:projectId/documents/templates/add",
  },
  EDIT_DMS_TEMPLATE: {
    TITLE: "Edit Document Template",
    HREF: "/project-management/projects/edit/:projectId/documents/templates/edit/:id",
  },
  PREVIEW_DMS_TEMPLATE: {
    TITLE: "Preview",
    HREF: "/project-management/projects/edit/:projectId/documents/templates/edit/:id/view/:fileId",
  },
  DOCUMENTS_TEMPLATES: {
    TITLE: "Templates",
    HREF: "/project-management/projects/edit/:projectId/documents#templates",
  },

  /** Change Requests Links */
  PROJECT_CHANGE_REQUEST: {
    TITLE: "Change Requests",
    HREF: "/project-management/projects/edit/:projectId/ecr",
  },
  ADD_CHANGE_REQUEST: {
    TITLE: "Add Change Requests",
    HREF: "/project-management/projects/edit/:projectId/ecr/add",
  },
  EDIT_CHANGE_REQUEST: {
    TITLE: "Edit Change Requests",
    HREF: "/project-management/projects/edit/:projectId/ecr/edit/:id",
  },
  LIBRARY: {
    TITLE: "Library",
    HREF: "/project-management/projects/edit/:projectId/library",
  },
  LIBRARY_NESTED: {
    TITLE: "Library",
    HREF: "/project-management/projects/edit/:projectId/library/:libraryId",
  },
  LIBRARY_CATEGORY_NESTED: {
    TITLE: "Library",
    HREF: "/project-management/projects/edit/:projectId/:category/library/:libraryId",
  },
  LIBRARY_FILE_VIEW: {
    TITLE: "File",
    HREF: "/project-management/projects/edit/:projectId/library/:libraryId/file/:fileId",
  },
  PATIENT_LIST: {
    TITLE: "Patient List",
    HREF: "/project-management/projects/edit/:projectId/patient-list",
  },
  EDIT_PATIENT: {
    TITLE: "Patient Data",
    HREF: "/project-management/projects/edit/:projectId/patient/:patientId",
  },
  ADD_PATIENT: {
    TITLE: "Register Patient",
    HREF: "/project-management/projects/edit/:projectId/patient/register",
  },
  DIGITAL_TEMPLATE: {
    TITLE: "Digital Template",
    HREF: "/project-management/projects/edit/:projectId/mainSystemId/:mainSystemId/system/:systemId",
  },
});

export default LINKS;
