import { lazy } from "react";
import { ReactComponent as TimesheetIcon } from "icons/timesheet.svg";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";

// const SignIn = lazy(() =>
//   lazyRetry(() => import(/* webpackChunkName: 'SignIn' */ "pages/auth/SignIn"))
// );

const TimesheetList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimesheetList")
  )
);

const TimeEntry = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimeEntry")
  )
);

// const TimeEntry = lazy(() =>
//   lazyRetry(() =>
//     import(
//       /* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimeEntry/newIndex"
//     )
//   )
// );

const SettingPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/SettingPage")
  )
);

const ExpenseList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'expense' */ "pages/Expenses/ExpensesList")
  )
);

const AddExpense = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'expense' */ "pages/Expenses/AddExpense")
  )
);

const EditExpense = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'expense' */ "pages/Expenses/EditExpense")
  )
);

const timesheetRoutes = Object.freeze({
  id: LINKS.TIME_MANAGEMENT.TITLE,
  path: LINKS.TIME_MANAGEMENT.HREF,
  // pathId: "timesheet",
  icon: <TimesheetIcon />,
  component: null,
  roles: LINKS.TIME_MANAGEMENT.ROLES,
  children: [
    {
      path: LINKS.TIMESHEET.HREF,
      name: LINKS.TIMESHEET.TITLE,
      roles: LINKS.TIMESHEET.ROLES,
      component: TimesheetList,
    },
    {
      path: LINKS.ADD_TIMESHEET_ENTRY.HREF,
      name: LINKS.ADD_TIMESHEET_ENTRY.TITLE,
      component: TimeEntry,
      isNotSlide: true,
    },
    {
      path: LINKS.TIMESHEET_SETTING.HREF,
      name: LINKS.TIMESHEET_SETTING.TITLE,
      component: SettingPage,
      isNotSlide: true,
    },
    {
      path: LINKS.EXPENSES.HREF,
      name: LINKS.EXPENSES.TITLE,
      component: ExpenseList,
      isNotSlide: true,
    },
    {
      path: LINKS.ADD_EXPENSE.HREF,
      name: LINKS.ADD_EXPENSE.TITLE,
      component: AddExpense,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_EXPENSE.HREF,
      name: LINKS.EDIT_EXPENSE.TITLE,
      component: EditExpense,
      isNotSlide: true,
    },
  ],
});

export default timesheetRoutes;
